import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { Link, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import Nav from 'react-bootstrap/Nav'
import { Tab, Tabs } from 'react-bootstrap'
import Star from '../../assets/images/icons/ratingStar.svg'
import NoData from '../../assets/images/icons/noDataImg.svg'
import SearchIcon from '../../assets/images/icons/SearchIcon.svg'
import FilterIcon from '../../assets/images/icons/Filter.svg'
import noData from '../../assets/images/icons/noDataImg.svg'
import emptyBox from '../../assets/images/icons/emptyBoxes.svg'
import Form from 'react-bootstrap/Form'
import AddIcon from '../../assets/images/icons/addsquare.svg'
import ApiConfig from '../../api/ApiConfig'
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices'
import RotatingLoader from '../../sharedComponent/RotatingLoader'
import NoDataMsg from '../../sharedComponent/NoDataMsg'

const ShopsInventory = () => {
  const { sidebar, setSidebar } = useContext(AppContext)
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }
  const params = useParams()
  const pharmacy_shop_id = Number(params.id)

  // STATES
  const [shopInventoryList, setShopInventoryList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [filterData, setFilterData] = useState({
    page: 1,
    search: '',
    product_category: ''
  })
  const [categoryFilter, setCategoryFilter] = useState({
    search: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  // STATES

  useEffect(() => {
    fetchLookupData()
  }, [])

  useEffect(() => {
    fetchShopInventoryList()
  }, [filterData])

  useEffect(() => {
    fetchShopInventoryCategoriesList()
  }, [categoryFilter])

  const fetchShopInventoryList = async () => {
    try {
      setIsLoading(true)
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.FETCH_SHOP_INVETORY}?pharmacy_shop_id=${pharmacy_shop_id}&product_name=${filterData.search}&product_type=${filterData.product_category}`
      )
      console.log('res,', res)
      const { success, data } = res?.json
      if (success) {
        data && setShopInventoryList(data)
      } else {
        setShopInventoryList([])
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchShopInventoryCategoriesList = async () => {
    try {
      setIsLoading(true)
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.FETCH_SHOP_INVETORY_CATEGORIES}?pharmacy_shop_id=${pharmacy_shop_id}&product_type=${categoryFilter.search}`
      )
      console.log('res,', res)
      const { success, data } = res?.json
      if (success && data) {
        setCategoryList(data)
      } else {
        setCategoryList([])
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setIsLoading(false)
    }
  }

  const [categories, setCategories] = useState([])
  const fetchLookupData = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_LOOKUP_DATA_PHARMACY}?productFor=petshop`
      )
      // console.log("res,", res);
      const { success, lookupData } = res?.json
      if (lookupData) {
        lookupData?.productTypes && setCategories(lookupData?.productTypes)
      }
    } catch (error) {
      console.log('catch error,', error)
    }
  }

  return (
    <>
      <motion.div
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
        className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'}
        id='cx-main'
      >
        <div className='shopsInventory-wrapper'>
          <div className='InventoryTabs'>
            <Tab.Container
              id='left-tabs-example'
              defaultActiveKey='ProductList'
            >
              <Nav variant='pills' id='newTabMai' className='tob_nav_pills'>
                <Nav.Item>
                  <Nav.Link eventKey='ProductList'>Product List</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='ProductCategory'>
                    Product Category
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey='ProductList'>
                  <div className='row'>
                    <div className='col-lg-3 cardsResponsive'>
                      <div className='searchBox'>
                        <img src={SearchIcon} className='searchImg' alt='' />
                        <input
                          type='text'
                          className='form-control searchDoc'
                          placeholder='Search by Name, ID...'
                          onChange={e => {
                            setFilterData({
                              ...filterData,
                              search: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-lg-3 cardsResponsive'>
                      <div className='selectBox'>
                        <Form.Select
                          aria-label='Default select example'
                          onChange={e => {
                            setFilterData({
                              ...filterData,
                              product_category: e.target.value
                                ? Number(e.target.value)
                                : ''
                            })
                          }}
                          value={
                            filterData?.product_category
                              ? filterData?.product_category
                              : ''
                          }
                        >
                          <option value=''>All Categories...</option>
                          {categories.map((item, idx) => {
                            return (
                              <option
                                value={item?.product_type_id}
                                key={item?.product_type_id}
                              >
                                {item?.product_type}
                              </option>
                            )
                          })}
                        </Form.Select>
                        {/* <Form.Select aria-label="Default select example">
                      <option>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select> */}
                      </div>
                    </div>
                    <div className='col-lg-2'>
                      {/* <div className='topFilter'>
                        <img src={FilterIcon} className='filterInner' alt='' />
                      </div> */}
                    </div>
                    <div className='col-lg-4 dNoneResponsive'></div>
                    {/* Cards section */}
                    <div className='slideBelow row p-0 m-0'>
                      {isLoading ? (
                        <RotatingLoader />
                      ) : shopInventoryList && shopInventoryList.length > 0 ? (
                        shopInventoryList.map((item, idx) => {
                          return (
                            <div
                              className='col-lg-3 cardsResponsive'
                              key={item.pharmacy_shop_product_id}
                            >
                              <div className='productCards'>
                                <img
                                  src={
                                    item.product_images &&
                                    item.product_images[0]?.product_image_path
                                      ? `${item.product_images[0]?.product_image_path}`
                                      : noData
                                  }
                                  className='cardImg'
                                  alt=''
                                />
                                <div className='starFlex'>
                                  <img
                                    src={Star}
                                    className='innerStar'
                                    alt=''
                                  />
                                  <label className='innerRate'>
                                    {item?.avg_rating}
                                  </label>
                                </div>
                                <div className='cardDetails'>
                                  <p>{item?.product}</p>
                                  <p>
                                    {item?.product_brand} | {item?.product_type}
                                  </p>
                                  <p className='paraTXt'>
                                    {item?.product_desc}
                                  </p>
                                </div>
                                <div className='quantityAnPrice'>
                                  <div className='row'>
                                    <div className='col-lg-6 text-center p-0'>
                                      <div className='quant'>
                                        <p>Quantity</p>
                                        <label className='Amnt'>
                                          {item?.pharmacy_shop_product_quantity}
                                        </label>
                                      </div>
                                    </div>
                                    <div className='col-lg-6 text-center p-0'>
                                      <div className='price'>
                                        <p>Price</p>
                                        <label className='Amnt'>
                                          {item?.product_rate}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      ) : (
                        <NoDataMsg />
                      )}
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='ProductCategory'>
                  <div className='row'>
                    <div className='col-lg-3 col-md-12'>
                      <div className='searchBox'>
                        <img src={SearchIcon} className='searchImg' alt='' />
                        <input
                          type='text'
                          className='form-control searchDoc'
                          placeholder='Search by name...'
                          onChange={e => {
                            setCategoryFilter({
                              ...categoryFilter,
                              search: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-lg-9 col-md-12'>
                      <div className='actionIcons d-flex justify-content-between'>
                        <div className='topFilter'>
                          <img
                            src={FilterIcon}
                            className='filterInner'
                            alt=''
                          />
                        </div>
                        <div className='rightBox'>
                          <Link to='/AddCategory'>
                            <div className='addBox'>
                              <img src={AddIcon} alt='' />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* Cards section */}
                    {isLoading ? (
                      <RotatingLoader />
                    ) : (
                      <div className='slideBelow row p-0 m-0'>
                        {categoryList && categoryList.length > 0 ? (
                          categoryList.map((item, idx) => {
                            return (
                              <div className='col-lg-3 cardsResponsive'>
                                <div className='productCards'>
                                  <img
                                    src={emptyBox}
                                    className='cardImg'
                                    alt=''
                                  />
                                  <div className='cardDetails'>
                                    <p className='productName'>
                                      {item?.category}
                                    </p>
                                  </div>
                                  <div className='quantityAnPrice'>
                                    <div className='row'>
                                      <div className='col-lg-6 text-center p-0'>
                                        <div className='quant'>
                                          <p className='greyTxt'>Products</p>
                                          <label className='Amnt'>
                                            {item?.total_products}
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-lg-6 text-center p-0'>
                                        <div className='price'>
                                          <p className='greyTxt'>Brands</p>
                                          <label className='Amnt'>
                                            {' '}
                                            {item?.total_brands}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        ) : (
                          <NoDataMsg />
                        )}
                      </div>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default ShopsInventory
