import { useNavigate } from "react-router-dom";
import { AppContext } from "./AppContext";
import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import ApiConfig from "../api/ApiConfig";
import { getYears } from "../common/commonFun";

const AppState = (props) => {
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);
  const [Dark, setDark] = useState("lightMode");
  const [bkdropLdr, setBkdropLdr] = useState(false);
  const [pharmacuEventKey, setPharmacuEventKey] = useState("ProductList");
  const [adminDetails, setAdminDetails] = useState({
    admin_id: localStorage.getItem("admin_id")
      ? localStorage.getItem("admin_id")
      : null,
    admin_image: localStorage.getItem("admin_image")
      ? localStorage.getItem("admin_image")
      : null,
    country_id: localStorage.getItem("country_id")
      ? localStorage.getItem("country_id")
      : null,
    state_id: localStorage.getItem("state_id")
      ? localStorage.getItem("state_id")
      : null,
    city_id: localStorage.getItem("city_id")
      ? localStorage.getItem("city_id")
      : null,
    admin_role_id: localStorage.getItem("admin_role_id")
      ? localStorage.getItem("admin_role_id")
      : null,
    is_super_admin: localStorage.getItem("is_super_admin")
      ? localStorage.getItem("is_super_admin")
      : null,
    is_active: localStorage.getItem("is_active")
      ? localStorage.getItem("is_active")
      : null,
    isLoggedIn: localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : null,
    user_id: localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : null,
  });
  const [pickup, setPickup] = useState({});
  const loggedInUserData = localStorage.user_loggedIn_data
    ? localStorage.user_loggedIn_data
    : {};
  const [socket, setSocket] = useState(null);
  const getYearDataArr = getYears(
    new Date().getFullYear() - 1,
    new Date().getFullYear()
  );
  const slideBelowRef = useRef(null);

  let option = {
    timeout: 20000,
    query: {
      UID: adminDetails?.user_id,
    },
  };

  // ================================ SOCKET CONNECTION =============================== //
  useEffect(() => {
    if (adminDetails?.user_id) {
      socketConnection();
    }
  }, [adminDetails?.user_id]);
  // ================================ SOCKET CONNECTION =============================== //

  const socketConnection = () => {
    const socketC = io(
      `${ApiConfig.SOCKET_URL}?UID=${adminDetails?.user_id}`,
      option
    );
    socketC && setSocket(socketC);
  };

  return (
    <div>
      <AppContext.Provider
        value={{
          getYearDataArr, 
          socket,
          navigate,
          sidebar,
          setSidebar,
          Dark,
          setDark,
          adminDetails,
          setAdminDetails,
          bkdropLdr,
          setBkdropLdr,
          loggedInUserData,
          pharmacuEventKey,
          setPharmacuEventKey,
          pickup,
          setPickup,
          slideBelowRef,
          navigate,
          aninations
        }}
      >
        {props.children}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;
