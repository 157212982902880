import React, { useContext, useEffect, useState } from "react";

import { motion } from "framer-motion";
import SearchIcon from "../assets/images/icons/SearchIcon.svg";

import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";

import {
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePostAuthCallWithFormData,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { notifyError, notifySuccess } from "../sharedComponent/Notify";
import moment from "moment";

import NoDataMsg from "../sharedComponent/NoDataMsg";
import Pagination from "../sharedComponent/Pagination";



const CustomerOppintment = () => {
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const { sidebar, setSidebar } = useContext(AppContext);
  const [withdrawlList, setWithdrawlList] = useState([]);
  const [paginationDataFromAPIResponse, setPaginationDataFromAPIResponse] =
    useState({});

  const [filteredData, setFilteredData] = useState({
    page: 1,
    page_limit: 20,
  });

  const [searchTxt, setSearchTxt] = useState("");
  useEffect(() => {
    fetchWithdrawList();
  }, [filteredData,searchTxt]);

  const fetchWithdrawList = async () => {
    // search, page, page_limit
    try {
      const res = await simpleGetCallWithToken(
        `${ApiConfig?.GET_DOCTORS_APPOINTMENT}?page=${filteredData?.page}&limit=${filteredData?.page_limit}&query=${searchTxt}`
      );
      const { success, data, last_page, page, total, total_page } = res;
      if (success) {
        data && setWithdrawlList(data);
        setPaginationDataFromAPIResponse({
          last_page,
          page,
          total,
          total_page,
        });
      } else {
        setWithdrawlList([]);
      }
    } catch (error) {
      console.log("cath error,", error);
    }
  };

  const pageChangeHandler = ({ selected }) => {
    setFilteredData({ ...filteredData, page: selected + 1 });
  };






  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
      
      <div className="col-md-2">
              <div className="innerSearchItem">
                <div className="leftFilterTop">
                  <div className="topSearch">
                    <input
                      type="text"
                      autoFocus
                      className="form-control searchDoc"
                      value={searchTxt}
                      onChange={(e) => {
                        setSearchTxt(e.target.value);
               
                      }}
                      placeholder=" Search by name, Doctor Name And Number"
                    />
                          <img src={SearchIcon} className="searchInner" alt="" 
                            style={{ cursor: "pointer", marginLeft: "5px",marginTop:"-65px" }}
                        />
              
                  </div>
                  {/* <div className="topFilter">
                        <img src={FilterIcon} className="filterInner" alt="" />
                      </div> */}
                </div>
               
              </div>
            </div>

        <div className="table-wrapper">
          {withdrawlList && withdrawlList.length > 0 ? (
            <table className="defaultTable">
              <thead>
                <tr>
                  <th>Sr No. </th>
                  <th>User Name</th>
                  <th>Clinic Name</th>
                  <th>Doctor Name</th>
                  <th>Email</th>
                  <th>Contact Number</th>
                  <th>Date</th>
           
                </tr>
              </thead>
              <tbody className="tableBody">
                {withdrawlList.map((item, index) => {
                  return (
                    <tr key={index + "withdrawlList"}>
                      <td>{index + 1}</td>
                      <td className="capital">{item?.first_name} {item?.last_name}</td>
                      <td>
                        {/* {data.state} */}
                       {item.doctor_clinic_name}
                      </td>
                      <td>
                        {item.doctor_name}
                        
                      </td>
                      <td>{item?.email}</td>
                  
                      <td>{item?.phone_number}</td>
                      <td>
                        {item?.date
                          ? moment(item?.date).format("DD-MM-YYYY")
                          : ""}
                      </td>
                 
                    </tr>
                  );
                })}
              </tbody>
            </table>
            
          ) : (
            <NoDataMsg MSG={"No Request Found"} />
          )}
           <Pagination
            pageCount={
              paginationDataFromAPIResponse?.total / filteredData?.page_limit
            }
            onPageChange={pageChangeHandler}
          />
        </div>
       
        <div>
         
        </div>
      </motion.div>
      <div className="withdraw-modal">
     
      </div>
    </>
  );
};

export default CustomerOppintment;
