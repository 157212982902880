import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import DoctorSahab from "../../assets/images/icons/doctorSahab.svg";
import collageImg from "../../assets/images/icons/bunchImg.svg";
import MoreImg from "../../assets/images/icons/moreImg+.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePostCall,
} from "../../api/ApiServices";
import { Nav, Tab } from "react-bootstrap";
import FileIn from "../../assets/images/icons/fileIcon.svg";
import { notifySuccess } from "../../sharedComponent/Notify";

const ViewDoctorDetails = () => {
  const params = useParams();

  const { sidebar, setSidebar } = useContext(AppContext);
  const [basicInfo, setBasicInfo] = useState([]);
  const [languageArr, setLanguageArr] = useState([]);
  const [basic, setBasic] = useState([]);
  const [servicesArr, setservicesArr] = useState([]);
  const [generalInfo, setGeneralInfo] = useState([]);
  const [general, setGeneral] = useState([]);
  const [clinicInfo, setClinicInfo] = useState([]);
  const [Clinic, setClinic] = useState(false);
  const [bank, seBank] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [profile, setProfile] = useState(false);
  const [doctorPro, setDoctorPro] = useState([]);
  const [doctorDocs, setDoctorDocs] = useState([]);
  const [signatureArr, setSignatureArr] = useState([]);

  const id = JSON.parse(params.id);
  // console.log(id)

  const getBasicInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.DOCTOR_BASIC_INFORMATION + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setBasic(true);
          setBasicInfo(res?.data);
          setLanguageArr(res?.data?.language);
        } else {
          setBasicInfo(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGeneralInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.DOCTOR_GENERAL_INFORMATION + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setGeneral(true);
          setGeneralInfo(res?.data);
          setservicesArr(res?.data?.specializations);
        } else {
          setGeneralInfo(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClinicInfo = () => {
    simpleGetCallWithToken(
      ApiConfig.DOCTOR_CLINIC_INFORMATION + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          setClinic(true);
          setClinicInfo(res?.data);
          if (res?.data?.facilitiesString) {
            let arr = res?.data?.facilitiesString;
            let facilities = arr.map((data, index) => {
              return data;
            });
          }
          // let facilitiesArrNested = facilitiesArr.map((data) => {
          //   return data;
          // });
          console.log("facilitiesArr =>", facilities);
          setFacilities(facilities);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBankDetails = () => {
    simpleGetCallWithToken(
      ApiConfig.GET_DOCTOR_BANK_DETAILS + `?doctor_id=${id}`
    )
      .then((res) => {
        if (res.success === true) {
          seBank(true);
          setBankDetails(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doctorProfile = () => {
    simpleGetCallWithToken(ApiConfig.DOCTOR_PROFILE + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setProfile(true);
          console.log(res);
          setDoctorPro(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocumentsInfo = () => {
    simpleGetCallWithToken(ApiConfig.GET_DOCTOR_DOCUMENTS + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setDoctorDocs(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocSignatur = () => {
    simpleGetCallWithToken(ApiConfig.GET_DOCTOR_SIGNATURE + `?doctor_id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setSignatureArr(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doctorAccept = (doctor_id) => {
    let payload = {
      doctor_id: doctor_id,
    };
    // Assuming `simplePostAuthCall` returns a Promise
    simplePostAuthCall(ApiConfig.DOCTOR_ACCEPT, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doctorReject = (doctor_id) => {
    let payload = {
      doctor_id: doctor_id,
    };
    // Assuming `simplePostAuthCall` returns a Promise
    simplePostAuthCall(ApiConfig.DOCTOR_REJECT, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBasicInfo();
    getGeneralInfo();
    getClinicInfo();
    getClinicInfo();
    getBankDetails();
    doctorProfile();
    getDocSignatur();
    getDocumentsInfo();
  }, []);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="viewDoctor-wrapper">
          <div className="mainDetailCard">
            <div className="topSection">
              <div className="innerImg">
                {profile ? (
                  <>
                    <img
                      src={
                        basicInfo?.doctor_profile_image_path
                          ? basicInfo?.doctor_profile_image_path
                          : ""
                      }
                      className="docImg"
                      alt=""
                    />
                    <div className="docDetTxt">
                      <h1 className="docName">
                        {doctorPro ? doctorPro[0]?.doctor_name : ""}
                      </h1>
                      <label className="docDomain">
                        {doctorPro[0]?.specializations}
                      </label>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={
                        basicInfo?.doctor_profile_image_path
                          ? basicInfo?.doctor_profile_image_path
                          : ""
                      }
                      // DoctorSahab
                      className="docImg"
                      alt=""
                    />
                    <div className="docDetTxt">
                      <h1 className="docName">{basicInfo?.doctor_name}</h1>
                      {/* <label className="docDomain">Not found</label> */}
                    </div>
                  </>
                )}
                {/* // <img src={DoctorSahab} className="docImg" alt="" />
                // <div className="docDetTxt">
                //   <h1 className="docName">Floyd Miles</h1>
                //   <label className="docDomain">Orthopedic</label>
                // </div> */}
              </div>
            </div>
            <div className="belowDetails">
              <div className="row">
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle">
                    <h1 className="heading">Basic Information</h1>
                  </div>
                </div>
                {/* Inner Values */}
                {basic ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Name</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_name}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Age</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_age}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Gender</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_gender}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Contact Number</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_contact_number}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Email</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_email}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Registration No.</h1>
                        <label htmlFor="" className="value">
                          {basicInfo?.doctor_registration_no}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Known Languages</h1>
                        {languageArr && languageArr.length > 0
                          ? languageArr.map((data, index) => {
                              return (
                                <>
                                  <label
                                    htmlFor=""
                                    className="value"
                                    key={index}
                                  >
                                    {data?.language + ","}
                                  </label>
                                </>
                              );
                            })
                          : "No language"}
                        {/* <label htmlFor="" className="value">
                                English, Hindi, Marathi, Urdu
                              </label> */}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 dispNoneMedia"></div>
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
                <div className="col-lg-3 col-md-6 col-sm-12 dispNoneMedia"></div>
                <div className="col-lg-3 col-md-6 col-sm-12 dispNoneMedia"></div>
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">General Information</h1>
                  </div>
                </div>
                {generalInfo ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Specialization</h1>
                        <ul>
                          {servicesArr && servicesArr.length > 0
                            ? servicesArr.map((data, index) => {
                                return (
                                  <li key={index}>{data?.specialization}</li>
                                );
                              })
                            : null}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Services</h1>
                        <label htmlFor="" className="value">
                          {generalInfo?.services}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Experience</h1>
                        <label htmlFor="" className="value">
                          {generalInfo?.experience}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="keyValueContent">
                        <h1 className="Key">Education</h1>
                        <label htmlFor="" className="value">
                          {generalInfo?.education}
                        </label>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Clinic Information</h1>
                  </div>
                </div>
                {Clinic ? (
                  <>
                    {clinicInfo && clinicInfo.length > 0
                      ? clinicInfo.map((data, index) => {
                          return (
                            <>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Clinic Name</h1>
                                  <label htmlFor="" className="value">
                                    {data?.doctor_clinic_name}
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Facilities</h1>
                                  <ul>
                                    {data.facilitiesString &&
                                    data.facilitiesString.length > 0
                                      ? data.facilitiesString.map(
                                          (data, index) => {
                                            return (
                                              <li key={index}>
                                                {data?.clinic_facility}
                                              </li>
                                            );
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Address</h1>
                                  <label htmlFor="" className="value">
                                    {data?.doctor_clinic_address}
                                  </label>
                                </div>
                              </div>
                              {/* <div className="col-lg-3 col-md-6 col-sm-12">
                              <div className="keyValueContent">
                                <h1 className="Key">Photos</h1>
                                <div className="imagesBunch">
                                  <img src={collageImg} alt="" />
                                  <img src={collageImg} alt="" />
                                  <img src={collageImg} alt="" />
                                  <img src={MoreImg} alt="" />
                                </div>
                              </div>
                            </div> */}
                            </>
                          );
                        })
                      : null}
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Documents</h1>
                  </div>
                </div>
                <div className="col-md-12">
                  <div id="docsTabs">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Nav variant="pills" className="flex-column">
                        {doctorDocs && doctorDocs.length > 0 ? (
                          doctorDocs.map((data, index) => {
                            return (
                              <Nav.Item>
                                <Nav.Link
                                        href={data?.clinic_certificate_path}
                                        target="_blank"
                                        eventKey={index}
                                      >
                                        <div className="fileInside">
                                          <div className="imgHolder">
                                            <img src={FileIn} alt="" />
                                          </div>
                                        </div>
                                      </Nav.Link>
                              </Nav.Item>
                            );
                          })
                        ) : (
                          <div className="col-md-12 text-center p-3 text-danger">
                            No Data Found
                          </div>
                        )}
                      </Nav>
                    </Tab.Container>
                  </div>
                </div>
                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Signature</h1>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="allSignsFlex">
                    <div className="signatureHolder">
                      <img
                        src={
                          signatureArr?.doctor_signature_path
                            ? signatureArr?.doctor_signature_path
                            : ""
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                {/* Heading Text */}
                <div className="col-md-12">
                  <div className="headTittle moreMt">
                    <h1 className="heading">Bank Details</h1>
                  </div>
                </div>
                {console.log(bankDetails)}
                {bank ? (
                  <>
                    {bankDetails && bankDetails.length > 0
                      ? bankDetails.map((data, index) => {
                          return (
                            <>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Bank Name</h1>
                                  <label htmlFor="" className="value">
                                    {data.doctor_bank}
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Account Number</h1>
                                  <label htmlFor="" className="value">
                                    {data.account_number}
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">Account Holder Name</h1>
                                  <label htmlFor="" className="value">
                                    {data.account_holder_name}
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="keyValueContent">
                                  <h1 className="Key">IFSC</h1>
                                  <label htmlFor="" className="value">
                                    {data.ifsc_code}
                                  </label>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : null}
                  </>
                ) : (
                  <div className="col-md-12 text-center p-3 text-danger">
                    No Data Found
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-end">
            <button
              className="rejectBtn me-2"
              onClick={() => {
                doctorReject(basicInfo?.doctor_id);
              }}
            >
              Reject
            </button>
            <button
              className="AcceptBtn"
              onClick={() => {
                doctorAccept(basicInfo?.doctor_id);
              }}
            >
              Accept
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ViewDoctorDetails;
