import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import Form from 'react-bootstrap/Form'
import FileSelect from '../../assets/images/icons/selectFile.svg'
import Select from 'react-select'
import {
  PostCallWithFormData,
  PutCallWithFormData,
  simpleGetCallWithErrorResponse
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import { convertInFormData } from '../../common/commonFun'
import { notifySuccess } from '../../sharedComponent/Notify'

const EditProduct = () => {
  const params = useParams()
  console.log('params,', params)
  const product_id = Number(params.id)
  console.log('product_id,', product_id)
  const { sidebar, navigate, setBkdropLdr } = useContext(AppContext)
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }

  // STATES
  const [validated, setValidated] = useState(false)
  const [addProductPayLoad, setAddProductPayLoad] = useState({
    product_name: '',
    product_brand_id: '',
    product_code: '',
    product_desc: '',
    product_id: '',
    product_rate: '',
    symptom_ids: [],
    type_of_animal_id: '',
    product_type_id: '',
    product_image: []
  })
  console.log('addProductPayLoad,', addProductPayLoad)
  const [lookupData, setLookupData] = useState({
    brand: [],
    symptoms: [],
    animalTypes: [],
    productTypes: [],
    medicineTypes : []
  })

  const { brand, symptoms, animalTypes, productTypes, medicineTypes } = lookupData

  const displaySelectedValue = symptoms.filter(item =>
    addProductPayLoad?.symptom_ids?.includes(item.value)
  )
  console.log('displaySelectedValue,', displaySelectedValue)
  useEffect(() => {
    fetchLookupData()
    fetchProductDetails()
  }, [])

  const fetchProductDetails = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_PRODUCT_DETAILS}/${product_id}`
      )
      console.log('res,', res)
      const { success, data } = res?.json
      if (success) {
        const images = data?.product_images?.map(img => img.product_image_path)
        console.log('data,', data)
        data &&
          setAddProductPayLoad({
            ...addProductPayLoad,
            product_name: data?.product ? data?.product : '',
            product_brand_id: data?.product_brand_id
              ? data?.product_brand_id
              : '',
            product_code: data?.product_code ? data?.product_code : '',
            product_desc: data?.product_desc ? data?.product_desc : '',
            product_id: data?.product_id ? data?.product_id : '',
            product_rate: data?.product_rate ? data?.product_rate : '',
            symptom_ids: data?.symptom_ids
              ? data?.symptom_ids.split(',').map(item => Number(item))
              : '',
            type_of_animal_id: data?.type_of_animal_id
              ? data?.type_of_animal_id
              : '',
            product_type_id: data?.product_type_id ? data?.product_type_id : '',
            product_image: images ? images : [],
            qauntity_per_package : data?.qauntity_per_package ? data?.qauntity_per_package : "",
            medicine_type_id : data?.medicine_type_id ? data?.medicine_type_id : "",
            is_featured : data?.is_featured 
          })
      }
    } catch (error) {
      console.log('catch error,', error)
    }
  }

  const fetchLookupData = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_LOOKUP_DATA_PHARMACY}?productFor=pharmacy`
      )
      const { lookupData } = res?.json
      if (lookupData) {
        const { brand, symptoms, animalTypes, productTypes, medicineTypes } = lookupData
        const tempArr =
          symptoms &&
          symptoms.map(item => {
            return { value: item?.symptom_id, label: item?.symptom }
          })
        setLookupData({
          brand: brand ? brand : [],
          symptoms: tempArr ? tempArr : [],
          animalTypes: animalTypes ? animalTypes : [],
          productTypes: productTypes ? productTypes : [],
          medicineTypes : medicineTypes ? medicineTypes : []
        })
      }
    } catch (error) {
      console.log('catch error ,', error)
    }
  }

  // ADD_PHARMACY_PRODUCT == products/addProduct
  const updatePharmacyProduct = async event => {
    event.preventDefault()
    try {
      const form = event.currentTarget
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        setValidated(true)
      } else {
        setBkdropLdr(true)
        const payLoadData = {
          ...addProductPayLoad,
          symptom_ids: addProductPayLoad.symptom_ids.join(',')
        }
        const formData = convertInFormData(payLoadData)
        const res = await PutCallWithFormData(
          ApiConfig.UPDATE_PHARMACY_PRODUCT,
          formData
        )
        const { success, data } = res?.json
        if (success) {
          notifySuccess('Product updated successfully')
          navigate('/MasterInventory')
        }
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  const onChangeHandler = e => {
    const { value, name } = e.target
    setAddProductPayLoad({
      ...addProductPayLoad,
      [name]: value
    })
  }

  return (
    <>
      <motion.div
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
        className={sidebar ? 'cx-main-wrapper' : 'cx-main-wrapper cx-active'}
        id='cx-main'
      >
        <Form noValidate validated={validated} onSubmit={updatePharmacyProduct}>
          <div className='addProduct-wrapper'>
            <div className='addCardWraper'>
              <div className='cardHeader'>
                <label className='headTxt'>Edit Product</label>
              </div>
              <div className='row'>
                <div className='col-lg-6 col-md-12'>
                  <div className='insideForm'>
                    <label className='inputLabel'>Product Name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Name'
                      required
                      value={
                        addProductPayLoad?.product_name
                          ? addProductPayLoad?.product_name
                          : ''
                      }
                      name='product_name'
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Product name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/*
                  <div className="col-lg-6 col-md-12">
                      <div className="insideForm">
                      <label className="inputLabel">Brand Name</label>
                      <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Brand Name"
                      />
                      </div>
                  </div>
              */}
                <div className='col-lg-6 col-md-12'>
                  <div className='insideForm'>
                    <label className='inputLabel'>Brand Name</label>
                    <Form.Select
                      aria-label='Default select example'
                      required
                      name='product_brand_id'
                      value={
                        addProductPayLoad?.product_brand_id
                          ? Number(addProductPayLoad?.product_brand_id)
                          : ''
                      }
                      onChange={e => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          product_brand_id: Number(e.target.value)
                        })
                      }}
                    >
                      <option value={''}>Select brand name</option>
                      {brand &&
                        brand.map((item, idx) => {
                          return (
                            <option
                              value={item?.product_brand_id}
                              key={'brands' + idx}
                            >
                              {item?.product_brand}
                            </option>
                          )
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      Brand name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='insideForm'>
                    <label className='inputLabel'>Animal Type</label>
                    <Form.Select
                      aria-label='Default select example'
                      required
                      name='type_of_animal_id'
                      value={
                        addProductPayLoad?.type_of_animal_id
                          ? Number(addProductPayLoad?.type_of_animal_id)
                          : ''
                      }
                      onChange={e => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          type_of_animal_id: Number(e.target.value)
                        })
                      }}
                    >
                      <option value={''}>Select animal type</option>
                      {animalTypes &&
                        animalTypes.map((item, idx) => {
                          return (
                            <option
                              value={item?.type_of_animal_id}
                              key={'animalTypes' + idx}
                            >
                              {item?.type_of_animal}
                            </option>
                          )
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      Animal type is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='insideForm'>
                    <label className='inputLabel'>Product Type</label>
                    <Form.Select
                      aria-label='Default select example'
                      required
                      value={
                        addProductPayLoad?.product_type_id
                          ? Number(addProductPayLoad?.product_type_id)
                          : ''
                      }
                      name='product_type_id'
                      onChange={e => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          product_type_id: Number(e.target.value)
                        })
                      }}
                    >
                      <option value={''}>Select product type</option>
                      {productTypes &&
                        productTypes.map((item, idx) => {
                          return (
                            <option
                              value={item?.product_type_id}
                              key={'productTypes' + idx}
                            >
                              {item?.product_type}
                            </option>
                          )
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      Product type is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Medicine Types</label>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      name="medicine_type_id"
                      value={addProductPayLoad?.medicine_type_id}
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          medicine_type_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select medicine type</option>
                      {medicineTypes &&
                        medicineTypes.map((item, idx) => {
                          return (
                            <option
                              value={item?.medicine_type_id}
                              key={"medicineTypes" + idx}
                            >
                              {item?.medicine_type}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Medicine type is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='insideForm'>
                    <label className='inputLabel'>Symptoms</label>
                    <Select
                      value={displaySelectedValue}
                      options={lookupData.symptoms}
                      isMulti
                      onChange={selectedValue => {
                        const symptomIds = selectedValue.map(item => item.value)
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          symptom_ids: symptomIds
                        })
                      }}
                    />
                  </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='insideForm'>
                    <label className='inputLabel'>Product Rate</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Name'
                      required
                      value={
                        addProductPayLoad?.product_rate
                          ? Number(addProductPayLoad?.product_rate)
                          : ''
                      }
                      name='product_rate'
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Product name is required
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Qauntity per package</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter qauntity..."
                      required
                      name="qauntity_per_package"
                      value={
                        addProductPayLoad?.qauntity_per_package
                          ? Number(addProductPayLoad?.qauntity_per_package)
                          : ''
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          qauntity_per_package: Number(value),
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter qauntity
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Featured</label>
                    <select
                      className="form-control"
                      value={addProductPayLoad.is_featured}
                      onChange={(e) => {
                        const isfeatured =
                          e.target.value == "true" ? true : false;
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          is_featured: isfeatured,
                        });
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                      Select Yes or No
                    </Form.Control.Feedback>
                  </div>
                </div>

               
                <div className='col-lg-6 col-md-12'>
                  <div className='insideForm'>
                    <label className='inputLabel'>Product Code</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Product Code'
                      required
                      value={
                        addProductPayLoad?.product_code
                          ? addProductPayLoad?.product_code
                          : ''
                      }
                      name='product_code'
                      onChange={e => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          product_code: e.target.value
                        })
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Product code is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='insideForm'>
                    <label className='inputLabel'>Brand Description</label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      style={{ height: '100px' }}
                      placeholder='Enter Product Description'
                      required
                      value={
                        addProductPayLoad?.product_desc
                          ? addProductPayLoad?.product_desc
                          : ''
                      }
                      name='product_desc'
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Brand name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='insideForm'>
                    <label className='inputLabel'>Product image</label>
                    <br />
                    <label htmlFor='SelectFile' className='selectFileBox'>
                      <input
                        type='file'
                        id='SelectFile'
                        className='d-none'
                        onChange={e => {
                          setAddProductPayLoad({
                            ...addProductPayLoad,
                            product_image: [e.target.files[0]]
                            // product_image: [
                            //   ...addProductPayLoad.product_image,
                            //   e.target.files[0]
                            // ]
                          })
                        }}
                      />
                      <img src={FileSelect} className='fileImg' alt='' />
                      <label htmlFor='' className='innerFIleTXt'>
                        Drop your files here.
                        <br />
                        or <span className='diffColor'>Browse</span>
                      </label>
                    </label>
                  </div>
                </div>
              </div>
              {addProductPayLoad.product_image.length ? (
                <div className='previewSection'>
                  <label className='previewLabel'>Preview</label>
                  <div className='previewImg'>
                    {addProductPayLoad.product_image.map((item, i) => {
                      return (
                        <img
                          // src={FileSelect}
                          src={
                            typeof item === 'object'
                              ? item && URL.createObjectURL(item)
                              : item
                          }
                          alt='im'
                        />
                      )
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            <div className='text-end'>
              <button
                type='button'
                className='rejectBtn me-2'
                onClick={() => navigate('/MasterInventory')}
              >
                Cancel
              </button>
              <button className='AcceptBtn'>Save</button>
            </div>
          </div>
        </Form>
      </motion.div>
    </>
  )
}

export default EditProduct
