import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
// import Logo from "../assets/images/icons/YourLogo.svg";
import dashboard from "../assets/images/sidebarIcons/dashboard.svg";
import dashboardActive from "../assets/images/sidebarIcons/dashboardActive.svg";
import doctors from "../assets/images/sidebarIcons/Doctors.svg";
import doctorsActive from "../assets/images/sidebarIcons/DoctorsActive.svg";
import Pharmacy from "../assets/images/sidebarIcons/pharmacy.svg";
import PharmacyActive from "../assets/images/sidebarIcons/PharmacyActive.svg";
import vetPet from "../assets/images/sidebarIcons/PetVet.svg";
import vetPetActive from "../assets/images/sidebarIcons/PetvetActive.svg";
import vacinators from "../assets/images/sidebarIcons/tabler_vaccine.svg";
import vacinatorsActive from "../assets/images/sidebarIcons/tabler_vaccineActive.svg";
import Ambulance from "../assets/images/sidebarIcons/Ambulance.svg";
import AmbulanceActive from "../assets/images/sidebarIcons/AmbulanceActive.svg";
import deliveryBoy from "../assets/images/sidebarIcons/DeliveryBoy.svg";
import deliveryBoyActive from "../assets/images/sidebarIcons/DeliveryBoyActive.svg";
import Admin from "../assets/images/sidebarIcons/Admin.svg";
import AdminActive from "../assets/images/sidebarIcons/AdminActive.svg";
import subs from "../assets/images/sidebarIcons/substack.svg";
import subsActive from "../assets/images/sidebarIcons/substackActive.svg";
import reports from "../assets/images/sidebarIcons/Reports.svg";
import reportsActive from "../assets/images/sidebarIcons/ReportsActive.svg";
import discount from "../assets/images/sidebarIcons/discount.svg";
import discountActive from "../assets/images/sidebarIcons/discountActive.svg";
import activeImg from "../assets/images/sidebarIcons/highlightImg.svg";
import rightArrow from "../assets/images/sidebarIcons/rightArrow.svg";
import arrowAActive from "../assets/images/sidebarIcons/ArrowActive.svg";
import Logout from "../assets/images/icons/logout.svg";
import Accordion from "react-bootstrap/Accordion";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import sideBarArrow from "../assets/images/sidebarIcons/SideBarArrow.svg";
import sideBarArrowActive from "../assets/images/sidebarIcons/SideBarArrowActive.svg";
import Logo from "../assets/images/icons/mainLogo.svg";
import withdrawal_icon from "../assets/images/withdrawal_icon.svg";

const Sidebar = () => {
  const { sidebar, setSidebar, adminDetails, setAdminDetails, socket } =
    useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname;

  const [pharmacy, setPharmacy] = useState(false);
  const [vetPets, setVetPets] = useState(false);
  const [adminS, setAdminS] = useState(false);
  const [report, setReport] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const logoutUser = () => {
    localStorage.clear();
    setAdminDetails({});
    socket.disconnect()
  };

  useEffect(() => {
    isMobile ? setSidebar(false) : setSidebar(true);
  }, [isMobile]);

  return (
    <>
      <div className="cx-sideBar">
        <div
          className={
            sidebar ? "cx-sidebar-wrapper" : "cx-sidebar-wrapper sidebarActive"
          }
        >
          <>
            <div className="logoMain">
              <Link to="/Dashboard">
                {sidebar ? (
                  <img src={Logo} alt="" />
                ) : (
                  <img src={Logo} className="smallimg" alt="" />
                )}
              </Link>
            </div>
            <div className="clickAbleArrow">
              {sidebar ? (
                <img
                  src={sideBarArrowActive}
                  className="SideBarActiveArrow"
                  onClick={() => setSidebar(!sidebar)}
                  alt=""
                />
              ) : (
                <img
                  src={sideBarArrow}
                  className="liteArrow"
                  onClick={() => setSidebar(!sidebar)}
                  alt=""
                />
              )}
            </div>
            {/* Dashboard */}
            <div className="overflowHeight">
              <Accordion>
                <div className="sidebarLinks">
                  <Link
                    to="/Dashboard"
                    className="targetLink"
                    onClick={() => {
                      isMobile ? setSidebar(false) : setSidebar(true);
                    }}
                  >
                    <div className="dashboardLink">
                      <img
                        src={
                          currentRoute === "/Dashboard"
                            ? dashboardActive
                            : dashboard
                        }
                        alt=""
                      />
                      <p
                        className={
                          currentRoute === "/Dashboard"
                            ? "activeTxt"
                            : "deActiveTxt"
                        }
                      >
                        Dashboard
                      </p>
                      <img
                        src={currentRoute === "/Dashboard" ? activeImg : null}
                        className="activelogo"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                {/* Doctors */}
                <div className="sidebarLinks">
                  <Link
                    to="/Doctors"
                    className="targetLink"
                    onClick={() => {
                      isMobile ? setSidebar(false) : setSidebar(true);
                    }}
                  >
                    <div className="dashboardLink">
                      <img
                        src={
                          currentRoute === "/Doctors" ||
                          currentRoute === "/DoctorDetails" ||
                          currentRoute === "/AddDoctor" ||
                          currentRoute === "/ViewDoctorDetails"
                            ? doctorsActive
                            : doctors
                        }
                        alt=""
                      />
                      <p
                        className={
                          currentRoute === "/Doctors" ||
                          currentRoute === "/DoctorDetails" ||
                          currentRoute === "/AddDoctor" ||
                          currentRoute === "/ViewDoctorDetails"
                            ? "activeTxt"
                            : "deActiveTxt"
                        }
                      >
                        Doctors
                      </p>
                      <img
                        src={
                          currentRoute === "/Doctors" ||
                          currentRoute === "/DoctorDetails" ||
                          currentRoute === "/AddDoctor" ||
                          currentRoute === "/ViewDoctorDetails"
                            ? activeImg
                            : null
                        }
                        className="activelogo"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>

{/* Doctors */}
<div className="sidebarLinks">
                  <Link
                    to="/customerappoinment"
                    className="targetLink"
                    onClick={() => {
                      isMobile ? setSidebar(false) : setSidebar(true);
                    }}
                  >
                    <div className="dashboardLink">
                      <img
                        src={
                          currentRoute === "/customerappoinment" 
                 
                            ? doctorsActive
                            : doctors
                        }
                        alt=""
                      />
                      <p
                        className={
                          currentRoute === "/customerappoinment" 
                      
                         
                            ? "activeTxt"
                            : "deActiveTxt"
                        }
                      >
                        Customer Appoinment
                      </p>
                      <img
                        src={
                         
                          currentRoute === "/customerappoinment"
                            ? activeImg
                            : null
                        }
                        className="activelogo"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>

                {/* Pharmacy */}
                {sidebar ? (
                  <>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        onClick={() => {
                          setPharmacy(!pharmacy);
                          setAdminS(false);
                          setReport(false);
                          setVetPets(false);
                        }}
                      >
                        <Link to="#" className="targetLink">
                          <div className="sidebarLinks">
                            <div className="dashboardLink">
                              <img
                                src={
                                  currentRoute === "/pharmacyShops" ||
                                  currentRoute === "/pharmacyInventory" ||
                                  currentRoute === "/PharmacyShopDetails" ||
                                  currentRoute === "/ShopsInventory" ||
                                  currentRoute === "/AddPharmacy" ||
                                  currentRoute === "/ViewShopDetails" ||
                                  currentRoute === "/MasterInventory" ||
                                  currentRoute === "/AddProduct" ||
                                  currentRoute === "/EditProduct" ||
                                  currentRoute === "/AddCategory" ||
                                  currentRoute === "/EditCategory"
                                    ? PharmacyActive
                                    : Pharmacy
                                }
                                alt=""
                              />
                              <p
                                className={
                                  currentRoute === "/pharmacyShops" ||
                                  currentRoute === "/pharmacyInventory" ||
                                  currentRoute === "/PharmacyShopDetails" ||
                                  currentRoute === "/ShopsInventory" ||
                                  currentRoute === "/AddPharmacy" ||
                                  currentRoute === "/ViewShopDetails" ||
                                  currentRoute === "/MasterInventory" ||
                                  currentRoute === "/AddProduct" ||
                                  currentRoute === "/EditProduct" ||
                                  currentRoute === "/AddCategory" ||
                                  currentRoute === "/EditCategory"
                                    ? "activeTxt"
                                    : "deActiveTxt"
                                }
                              >
                                Pharmacy
                              </p>
                              <img
                                src={
                                  currentRoute === "/pharmacyShops" ||
                                  currentRoute === "/pharmacyInventory" ||
                                  currentRoute === "/PharmacyShopDetails" ||
                                  currentRoute === "/ShopsInventory" ||
                                  currentRoute === "/AddPharmacy" ||
                                  currentRoute === "/ViewShopDetails" ||
                                  currentRoute === "/MasterInventory" ||
                                  currentRoute === "/AddProduct" ||
                                  currentRoute === "/EditProduct" ||
                                  currentRoute === "/AddCategory" ||
                                  currentRoute === "/EditCategory"
                                    ? activeImg
                                    : null
                                }
                                className="activelogo"
                                alt=""
                              />
                              <img
                                src={pharmacy ? arrowAActive : rightArrow}
                                className={sidebar ? "arrowSetting" : "d-none"}
                                alt=""
                              />
                            </div>
                          </div>
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="menu_items">
                          <Link
                            to="/pharmacyShops"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/pharmacyShops" ||
                                currentRoute === "/PharmacyShopDetails" ||
                                currentRoute === "/ShopsInventory" ||
                                currentRoute === "/AddPharmacy" ||
                                currentRoute === "/ViewShopDetails"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Shops
                            </div>
                          </Link>
                          <Link
                            to="/MasterInventory"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/MasterInventory" ||
                                currentRoute === "/AddProduct" ||
                                currentRoute === "/EditProduct" ||
                                currentRoute === "/AddCategory" ||
                                currentRoute === "/EditCategory"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Master Inventory
                            </div>
                          </Link>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <>
                    <div className="sidebarLinks">
                      <Link
                        to="/pharmacyShops"
                        className="targetLink"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        <div className="dashboardLink">
                          <img
                            src={
                              currentRoute === "/pharmacyShops" ||
                              currentRoute === "/pharmacyInventory" ||
                              currentRoute === "/PharmacyShopDetails" ||
                              currentRoute === "/ShopsInventory" ||
                              currentRoute === "/AddPharmacy" ||
                              currentRoute === "/ViewShopDetails" ||
                              currentRoute === "/MasterInventory" ||
                              currentRoute === "/AddProduct" ||
                              currentRoute === "/EditProduct" ||
                              currentRoute === "/AddCategory" ||
                              currentRoute === "/EditCategory"
                                ? PharmacyActive
                                : Pharmacy
                            }
                            alt=""
                          />
                          <img
                            src={
                              currentRoute === "/pharmacyShops" ||
                              currentRoute === "/pharmacyInventory" ||
                              currentRoute === "/PharmacyShopDetails" ||
                              currentRoute === "/ShopsInventory" ||
                              currentRoute === "/AddPharmacy" ||
                              currentRoute === "/ViewShopDetails" ||
                              currentRoute === "/MasterInventory" ||
                              currentRoute === "/AddProduct" ||
                              currentRoute === "/EditProduct" ||
                              currentRoute === "/AddCategory" ||
                              currentRoute === "/EditCategory"
                                ? activeImg
                                : null
                            }
                            className="activelogo"
                            alt=""
                          />
                        </div>
                      </Link>
                    </div>
                  </>
                )}
                {/* Pharmacy */}
                {sidebar ? (
                  <>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        onClick={() => {
                          setVetPets(!vetPets);
                          setPharmacy(false);
                          setAdminS(false);
                          setReport(false);
                        }}
                      >
                        <Link to="#" className="targetLink">
                          <div className="sidebarLinks">
                            <div className="dashboardLink">
                              <img
                                src={
                                  currentRoute === "/vetPetShops" ||
                                  currentRoute === "/PetShopDetails" ||
                                  currentRoute === "/PetShopInventory" ||
                                  currentRoute === "/AddPetShops" ||
                                  currentRoute === "/ViewPetShops" ||
                                  currentRoute === "/vetPetInventory" ||
                                  currentRoute === "/AddPetProduct" ||
                                  currentRoute === "/EditPetProduct" ||
                                  currentRoute === "/AddPetCategory" ||
                                  currentRoute === "/EditPetCategory"
                                    ? vetPetActive
                                    : vetPet
                                }
                                alt=""
                              />
                              <p
                                className={
                                  currentRoute === "/vetPetShops" ||
                                  currentRoute === "/PetShopDetails" ||
                                  currentRoute === "/PetShopInventory" ||
                                  currentRoute === "/AddPetShops" ||
                                  currentRoute === "/ViewPetShops" ||
                                  currentRoute === "/vetPetInventory" ||
                                  currentRoute === "/AddPetProduct" ||
                                  currentRoute === "/EditPetProduct" ||
                                  currentRoute === "/AddPetCategory" ||
                                  currentRoute === "/EditPetCategory"
                                    ? "activeTxt"
                                    : "deActiveTxt"
                                }
                              >
                                Vet / Pet Shops
                              </p>
                              <img
                                src={
                                  currentRoute === "/vetPetShops" ||
                                  currentRoute === "/PetShopDetails" ||
                                  currentRoute === "/PetShopInventory" ||
                                  currentRoute === "/AddPetShops" ||
                                  currentRoute === "/ViewPetShops" ||
                                  currentRoute === "/vetPetInventory" ||
                                  currentRoute === "/AddPetProduct" ||
                                  currentRoute === "/EditPetProduct" ||
                                  currentRoute === "/AddPetCategory" ||
                                  currentRoute === "/EditPetCategory"
                                    ? activeImg
                                    : null
                                }
                                className="activelogo"
                                alt=""
                              />
                              <img
                                src={vetPets ? arrowAActive : rightArrow}
                                className="arrowSetting"
                                alt=""
                              />
                            </div>
                          </div>
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="menu_items">
                          <Link
                            to="/vetPetShops"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/vetPetShops" ||
                                currentRoute === "/PetShopDetails" ||
                                currentRoute === "/PetShopInventory" ||
                                currentRoute === "/AddPetShops" ||
                                currentRoute === "/ViewPetShops"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Shops
                            </div>
                          </Link>
                          <Link
                            to="/vetPetInventory"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/vetPetInventory" ||
                                currentRoute === "/AddPetProduct" ||
                                currentRoute === "/EditPetProduct" ||
                                currentRoute === "/AddPetCategory" ||
                                currentRoute === "/EditPetCategory"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Master Inventory
                            </div>
                          </Link>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <>
                    <div className="sidebarLinks">
                      <Link
                        to="/vetPetShops"
                        className="targetLink"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        <div className="dashboardLink">
                          <img
                            src={
                              currentRoute === "/vetPetShops" ||
                              currentRoute === "/PetShopDetails" ||
                              currentRoute === "/PetShopInventory" ||
                              currentRoute === "/AddPetShops" ||
                              currentRoute === "/ViewPetShops" ||
                              currentRoute === "/vetPetInventory" ||
                              currentRoute === "/AddPetProduct" ||
                              currentRoute === "/EditPetProduct" ||
                              currentRoute === "/AddPetCategory" ||
                              currentRoute === "/EditPetCategory"
                                ? vetPetActive
                                : vetPet
                            }
                            alt=""
                          />
                          <img
                            src={
                              currentRoute === "/vetPetShops" ||
                              currentRoute === "/PetShopDetails" ||
                              currentRoute === "/PetShopInventory" ||
                              currentRoute === "/AddPetShops" ||
                              currentRoute === "/ViewPetShops" ||
                              currentRoute === "/vetPetInventory" ||
                              currentRoute === "/AddPetProduct" ||
                              currentRoute === "/EditPetProduct" ||
                              currentRoute === "/AddPetCategory" ||
                              currentRoute === "/EditPetCategory"
                                ? activeImg
                                : null
                            }
                            className="activelogo"
                            alt=""
                          />
                        </div>
                      </Link>
                    </div>
                  </>
                )}
                {/* Vaccinator */}
                <div className="sidebarLinks">
                  <Link
                    to="/Vaccinators"
                    className="targetLink"
                    onClick={() => {
                      isMobile ? setSidebar(false) : setSidebar(true);
                    }}
                  >
                    <div className="dashboardLink">
                      <img
                        src={
                          (currentRoute === "/Vaccinators" && "Vaccinator’s") ||
                          (currentRoute === "/VaccinatorDetails" &&
                            "Vaccinator Details") ||
                          (currentRoute === "/AddVacinators" &&
                            "Add Vaccinator’s") ||
                          (currentRoute === "/ViewVaccinators" &&
                            "Vaccinator Details")
                            ? vacinatorsActive
                            : vacinators
                        }
                        alt=""
                      />
                      <p
                        className={
                          (currentRoute === "/Vaccinators" && "Vaccinator’s") ||
                          (currentRoute === "/VaccinatorDetails" &&
                            "Vaccinator Details") ||
                          (currentRoute === "/AddVacinators" &&
                            "Add Vaccinator’s") ||
                          (currentRoute === "/ViewVaccinators" &&
                            "Vaccinator Details")
                            ? "activeTxt"
                            : "deActiveTxt"
                        }
                      >
                        Vaccinators
                      </p>
                      <img
                        src={
                          (currentRoute === "/Vaccinators" && "Vaccinator’s") ||
                          (currentRoute === "/VaccinatorDetails" &&
                            "Vaccinator Details") ||
                          (currentRoute === "/AddVacinators" &&
                            "Add Vaccinator’s") ||
                          (currentRoute === "/ViewVaccinators" &&
                            "Vaccinator Details")
                            ? activeImg
                            : null
                        }
                        className="activelogo"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                {/* Ambulance */}
                <div className="sidebarLinks">
                  <Link
                    to="/Ambulances"
                    className="targetLink"
                    onClick={() => {
                      isMobile ? setSidebar(false) : setSidebar(true);
                    }}
                  >
                    <div className="dashboardLink">
                      <img
                        src={
                          currentRoute === "/Ambulances" ||
                          currentRoute === "/AmbulanceDetails" ||
                          currentRoute === "/AddAmbulance" ||
                          currentRoute === "/ViewAmbulance"
                            ? AmbulanceActive
                            : Ambulance
                        }
                        alt=""
                      />
                      <p
                        className={
                          currentRoute === "/Ambulances" ||
                          currentRoute === "/AmbulanceDetails" ||
                          currentRoute === "/AddAmbulance" ||
                          currentRoute === "/ViewAmbulance"
                            ? "activeTxt"
                            : "deActiveTxt"
                        }
                      >
                        Ambulances
                      </p>
                      <img
                        src={
                          currentRoute === "/Ambulances" ||
                          currentRoute === "/AmbulanceDetails" ||
                          currentRoute === "/AddAmbulance" ||
                          currentRoute === "/ViewAmbulance"
                            ? activeImg
                            : null
                        }
                        className="activelogo"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                {/* Delivery Boy */}
                <div className="sidebarLinks">
                  <Link
                    to="/DeliveryBoy"
                    className="targetLink"
                    onClick={() => {
                      isMobile ? setSidebar(false) : setSidebar(true);
                    }}
                  >
                    <div className="dashboardLink">
                      <img
                        src={
                          currentRoute === "/DeliveryBoy" ||
                          currentRoute === "/DeliveryBoyDetails" ||
                          currentRoute === "/AddDeliveryBoy" ||
                          currentRoute === "/ViewDeilveryBoy"
                            ? deliveryBoyActive
                            : deliveryBoy
                        }
                        alt=""
                      />
                      <p
                        className={
                          currentRoute === "/DeliveryBoy" ||
                          currentRoute === "/DeliveryBoyDetails" ||
                          currentRoute === "/AddDeliveryBoy" ||
                          currentRoute === "/ViewDeilveryBoy"
                            ? "activeTxt"
                            : "deActiveTxt"
                        }
                      >
                        Delivery Boy
                      </p>
                      <img
                        src={
                          currentRoute === "/DeliveryBoy" ||
                          currentRoute === "/DeliveryBoyDetails" ||
                          currentRoute === "/AddDeliveryBoy" ||
                          currentRoute === "/ViewDeilveryBoy"
                            ? activeImg
                            : null
                        }
                        className="activelogo"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                {/* Admin */}
                {sidebar ? (
                  <>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        onClick={() => {
                          setAdminS(!adminS);
                          setReport(false);
                          setPharmacy(false);
                          setVetPets(false);
                        }}
                      >
                        <Link to="#" className="targetLink">
                          <div className="sidebarLinks">
                            <div className="dashboardLink">
                              <img
                                src={
                                  currentRoute === "/StateAdmin" ||
                                  currentRoute === "/DistrictAdmin" ||
                                  currentRoute === "/CityAdmin"
                                    ? AdminActive
                                    : Admin
                                }
                                alt=""
                              />
                              <p
                                className={
                                  currentRoute === "/StateAdmin" ||
                                  currentRoute === "/DistrictAdmin" ||
                                  currentRoute === "/CityAdmin"
                                    ? "activeTxt"
                                    : "deActiveTxt"
                                }
                              >
                                Admin
                              </p>
                              <img
                                src={
                                  currentRoute === "/StateAdmin" ||
                                  currentRoute === "/DistrictAdmin" ||
                                  currentRoute === "/CityAdmin"
                                    ? activeImg
                                    : null
                                }
                                className="activelogo"
                                alt=""
                              />
                              <img
                                src={adminS ? arrowAActive : rightArrow}
                                className="arrowSetting"
                                alt=""
                              />
                            </div>
                          </div>
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="menu_items">
                          <Link
                            to="/StateAdmin"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/StateAdmin"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              State Admin
                            </div>
                          </Link>
                          <Link
                            to="/DistrictAdmin"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/DistrictAdmin"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              District Admin
                            </div>
                          </Link>
                          <Link
                            to="/CityAdmin"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/CityAdmin"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              City Admin
                            </div>
                          </Link>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <>
                    <div className="sidebarLinks">
                      <Link
                        to="/StateAdmin"
                        className="targetLink"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        <div className="dashboardLink">
                          <img
                            src={
                              currentRoute === "/StateAdmin" ||
                              currentRoute === "/DistrictAdmin" ||
                              currentRoute === "/CityAdmin"
                                ? AdminActive
                                : Admin
                            }
                            alt=""
                          />
                          <img
                            src={
                              currentRoute === "/StateAdmin" ||
                              currentRoute === "/DistrictAdmin" ||
                              currentRoute === "/CityAdmin"
                                ? activeImg
                                : null
                            }
                            className="activelogo"
                            alt=""
                          />
                        </div>
                      </Link>
                    </div>
                  </>
                )}

                <div className="sidebarLinks">
                  <Link
                    to="/withdrawlist"
                    className="targetLink"
                    onClick={() => {
                      isMobile ? setSidebar(false) : setSidebar(true);
                    }}
                  >
                    <div className="dashboardLink">
                      <img
                        style={{
                          opacity: "0.5",
                        }}
                        src={
                          currentRoute === "/withdrawlist"
                            ? withdrawal_icon
                            : withdrawal_icon
                        }
                        height="20px"
                        alt=""
                      />
                      <p
                        className={
                          currentRoute === "/withdrawlist" 
                            ? "activeTxt"
                            : "deActiveTxt"
                        }
                      >
                        Withdraw Management
                      </p>
                      <img
                        src={
                          currentRoute === "/withdrawlist"
                            ? activeImg
                            : null
                        }
                        className="activelogo"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>

                {/* Reports */}
                {sidebar ? (
                  <>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header
                        onClick={() => {
                          setReport(!report);
                          setPharmacy(false);
                          setAdminS(false);
                          setVetPets(false);
                        }}
                      >
                        <Link to="#" className="targetLink">
                          <div className="sidebarLinks">
                            <div className="dashboardLink">
                              <img
                                src={
                                  currentRoute === "/RevenueReports" ||
                                  currentRoute === "/UsersReports" ||
                                  currentRoute === "/OtherReports"
                                    ? reportsActive
                                    : reports
                                }
                                alt=""
                              />
                              <p
                                className={
                                  currentRoute === "/RevenueReports" ||
                                  currentRoute === "/UsersReports" ||
                                  currentRoute === "/OtherReports"
                                    ? "activeTxt"
                                    : "deActiveTxt"
                                }
                              >
                                Reports
                              </p>
                              <img
                                src={
                                  currentRoute === "/RevenueReports" ||
                                  currentRoute === "/UsersReports" ||
                                  currentRoute === "/OtherReports"
                                    ? activeImg
                                    : null
                                }
                                className="activelogo"
                                alt=""
                              />
                              <img
                                src={report ? arrowAActive : rightArrow}
                                className="arrowSetting"
                                alt=""
                              />
                            </div>
                          </div>
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="menu_items">
                          <Link
                            to="/RevenueReports"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/RevenueReports"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Revenue Report
                            </div>
                          </Link>
                          <Link
                            to="/UsersReports"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/UsersReports"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Users Report
                            </div>
                          </Link>
                          <Link
                            to="/OtherReports"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/OtherReports"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Other Reports
                            </div>
                          </Link>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <>
                    <div className="sidebarLinks">
                      <Link
                        to="/RevenueReports"
                        className="targetLink"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        <div className="dashboardLink">
                          <img
                            src={
                              currentRoute === "/RevenueReports" ||
                              currentRoute === "/UsersReports" ||
                              currentRoute === "/OtherReports"
                                ? reportsActive
                                : reports
                            }
                            alt=""
                          />
                          <img
                            src={
                              currentRoute === "/RevenueReports" ||
                              currentRoute === "/UsersReports" ||
                              currentRoute === "/OtherReports"
                                ? activeImg
                                : null
                            }
                            className="activelogo"
                            alt=""
                          />
                        </div>
                      </Link>
                    </div>
                  </>
                )}
                {sidebar ? (
                  <>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header
                        onClick={() => {
                          setSubscription(!subscription);
                          setReport(false);
                          setPharmacy(false);
                          setAdminS(false);
                          setVetPets(false);
                        }}
                      >
                        <Link to="#" className="targetLink">
                          <div className="sidebarLinks">
                            <div className="dashboardLink">
                              <img
                                src={
                                  currentRoute === "/SubscriptionPlan" ||
                                  currentRoute === "/SubscriptionRequest" ||
                                  currentRoute === "/AddSubscription" ||
                                  currentRoute === "/ActiveSubscription" ||
                                  currentRoute === "/EditSubscription"
                                    ? subsActive
                                    : subs
                                }
                                alt=""
                              />
                              <p
                                className={
                                  currentRoute === "/SubscriptionPlan" ||
                                  currentRoute === "/SubscriptionRequest" ||
                                  currentRoute === "/AddSubscription" ||
                                  currentRoute === "/ActiveSubscription" ||
                                  currentRoute === "/EditSubscription"
                                    ? "activeTxt"
                                    : "deActiveTxt"
                                }
                              >
                                Subscription
                              </p>
                              <img
                                src={
                                  currentRoute === "/SubscriptionPlan" ||
                                  currentRoute === "/SubscriptionRequest" ||
                                  currentRoute === "/ActiveSubscription" ||
                                  currentRoute === "/EditSubscription" ||
                                  currentRoute === "/AddSubscription"
                                    ? activeImg
                                    : null
                                }
                                className="activelogo"
                                alt=""
                              />
                              <img
                                src={subscription ? arrowAActive : rightArrow}
                                className="arrowSetting"
                                alt=""
                              />
                            </div>
                          </div>
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="menu_items">
                          <Link
                            to="/SubscriptionPlan"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/SubscriptionPlan" ||
                                currentRoute === "/AddSubscription" ||
                                currentRoute === "/EditSubscription"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Subscription Plans
                            </div>
                          </Link>
                          <Link
                            to="/SubscriptionRequest"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/SubscriptionRequest"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Request Subscription
                            </div>
                          </Link>
                          <Link
                            to="/ActiveSubscription"
                            className="menuLink"
                            onClick={() => {
                              isMobile ? setSidebar(false) : setSidebar(true);
                            }}
                          >
                            <div
                              className={
                                currentRoute === "/ActiveSubscription"
                                  ? "activeTxt"
                                  : "deActiveTxt"
                              }
                            >
                              Active Subscription
                            </div>
                          </Link>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <>
                    <div className="sidebarLinks">
                      <Link
                        to="/RevenueReports"
                        className="targetLink"
                        onClick={() => {
                          isMobile ? setSidebar(false) : setSidebar(true);
                        }}
                      >
                        <div className="dashboardLink">
                          <img
                            src={
                              currentRoute === "/RevenueReports" ||
                              currentRoute === "/UsersReports" ||
                              currentRoute === "/OtherReports"
                                ? reportsActive
                                : reports
                            }
                            alt=""
                          />
                          <img
                            src={
                              currentRoute === "/RevenueReports" ||
                              currentRoute === "/UsersReports" ||
                              currentRoute === "/OtherReports"
                                ? activeImg
                                : null
                            }
                            className="activelogo"
                            alt=""
                          />
                        </div>
                      </Link>
                    </div>
                  </>
                )}
              </Accordion>
              <div className="sidebarLinks">
                <Link
                  to="/DiscountList"
                  className="targetLink"
                  onClick={() => {
                    isMobile ? setSidebar(false) : setSidebar(true);
                  }}
                >
                  <div className="dashboardLink">
                    <img
                      src={
                        currentRoute === "/DiscountList" ||
                        currentRoute === "/AddDiscount" ||
                        currentRoute.includes("/EditDiscount")
                          ? discountActive
                          : discount
                      }
                      height="20px"
                      alt=""
                    />
                    <p
                      className={
                        currentRoute === "/DiscountList" ||
                        currentRoute === "/AddDiscount" ||
                        currentRoute.includes("/EditDiscount")
                          ? "activeTxt"
                          : "deActiveTxt"
                      }
                    >
                      Discount
                    </p>
                    <img
                      src={
                        currentRoute === "/DiscountList" ||
                        currentRoute === "/AddDiscount" ||
                        currentRoute.includes("/EditDiscount")
                          ? activeImg
                          : null
                      }
                      className="activelogo"
                      alt=""
                    />
                  </div>
                </Link>
              </div>
              <div className="sidebarLinks">
                <Link
                  to="#"
                  className="targetLink"
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  <div className="dashboardLink">
                    <img src={Logout} alt="" />
                    <p className="deActiveTxt">Logout</p>
                  </div>
                </Link>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
