import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Form from "react-bootstrap/Form";
import FileSelect from "../../assets/images/icons/selectFile.svg";
import { convertInFormData } from "../../common/commonFun";
import {
  PostCallWithFormData,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import { notifySuccess } from "../../sharedComponent/Notify";
import ApiConfig from "../../api/ApiConfig";
import Select from "react-select";

const AddPetProduct = () => {
  const { sidebar, setBkdropLdr, navigate } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  // STATES
  const [validated, setValidated] = useState(false);
  const [addProductPayLoad, setAddProductPayLoad] = useState({
    product_name: "",
    product_brand_id: "",
    product_code: 0,
    product_desc: "",
    productFor: "petshop",
    product_rate: "",
    symptom_ids: [],
    type_of_animal_id: "",
    animal_grooming_id: "",
    product_type_id: "",
    product_image: "",
    is_featured : false
  });
  console.log("addProductPayLoad,", addProductPayLoad);

  useEffect(() => {
    fetchLookupData();
  }, []);

  const [lookupData, setLookupData] = useState({
    brand: [],
    symptoms: [],
    animalTypes: [],
    productTypes: [],
  });

  const { brand, symptoms, animalTypes, productTypes, animalGrooming } =
    lookupData;

  const fetchLookupData = async () => {
    try {
      const res = await simpleGetCallWithErrorResponse(
        `${ApiConfig.GET_LOOKUP_DATA_PHARMACY}?productFor=petshop`
      );
      console.log("res,", res);
      const { lookupData } = res?.json;
      if (lookupData) {
        const { brand, symptoms, animalTypes, productTypes, animalGrooming } =
          lookupData;
        const tempArr =
          symptoms &&
          symptoms.map((item) => {
            return { value: item?.symptom_id, label: item?.symptom };
          });
        setLookupData({
          brand: brand ? brand : [],
          symptoms: tempArr ? tempArr : [],
          animalTypes: animalTypes ? animalTypes : [],
          productTypes: productTypes ? productTypes : [],
          animalGrooming: animalGrooming ? animalGrooming : [],
        });
      }
    } catch (error) {}
  };

  // ADD_PHARMACY_PRODUCT == products/addProduct
  const addPharmacyProduct = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      } else {
        setBkdropLdr(true);
        const formData = convertInFormData(addProductPayLoad);
        const res = await PostCallWithFormData(
          ApiConfig.ADD_PHARMACY_PRODUCT,
          formData
        );
        const { success, data } = res?.json;
        if (success) {
          notifySuccess("Product added successfully");
          navigate("/vetPetInventory");
        }
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setAddProductPayLoad({
      ...addProductPayLoad,
      [name]: value,
    });
  };
  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <Form noValidate validated={validated} onSubmit={addPharmacyProduct}>
          <div className="addProduct-wrapper">
            <div className="addCardWraper">
              <div className="cardHeader">
                <label className="headTxt">Add New Product</label>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      name="product_name"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Product name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/*
              <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                  <label className="inputLabel">Brand Name</label>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Brand Name"
                  />
                  </div>
              </div>
          */}
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Brand Name</label>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      name="product_brand_id"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          product_brand_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select brand name</option>
                      {brand &&
                        brand.map((item, idx) => {
                          return (
                            <option
                              value={item?.product_brand_id}
                              key={"brands" + idx}
                            >
                              {item?.product_brand}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Brand name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Animal Type</label>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      name="type_of_animal_id"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          type_of_animal_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select animal type</option>
                      {animalTypes &&
                        animalTypes.map((item, idx) => {
                          return (
                            <option
                              value={item?.type_of_animal_id}
                              key={"animalTypes" + idx}
                            >
                              {item?.type_of_animal}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Animal type is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/* animalGrooming */}
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Animal Grooming Type</label>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      name="animal_grooming_id"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          animal_grooming_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select grooming type</option>
                      {animalGrooming &&
                        animalGrooming.map((item, idx) => {
                          return (
                            <option
                              value={item?.animal_grooming_id}
                              key={"animalGrooming" + idx}
                            >
                              {item?.animal_grooming}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Animal grooming is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product Type</label>
                    <Form.Select
                      aria-label="Default select example"
                      required
                      name="product_type_id"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          product_type_id: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={""}>Select product type</option>
                      {productTypes &&
                        productTypes.map((item, idx) => {
                          return (
                            <option
                              value={item?.product_type_id}
                              key={"productTypes" + idx}
                            >
                              {item?.product_type}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Product type is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                {/**
            <div className="col-lg-6 col-md-12">
                <div className="insideForm">
                  <label className="inputLabel">Animal Grooming</label>
                  <Form.Select
                    aria-label="Default select example"
                    // required
                    name="animal_grooming_id"
                    onChange={(e) => {
                      setAddProductPayLoad({
                        ...addProductPayLoad,
                        animal_grooming_id: Number(e.target.value),
                      });
                    }}
                  >
                    <option value={""}>Select product type</option>
                    {productTypes &&
                      productTypes.map((item, idx) => {
                        return (
                          <option
                            value={item?.product_type_id}
                            key={"productTypes" + idx}
                          >
                            {item?.product_type}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Product type is required
                  </Form.Control.Feedback>
                </div>
              </div>
            */}

                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product Rate</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      name="product_rate"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Product name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Featured</label>
                    <select
                      className="form-control"
                      value={addProductPayLoad.is_featured}
                      onChange={(e) => {
                        console.log("e.target,", e.target.value);
                        const isfeatured =
                          e.target.value == "true" ? true : false;
                          const { value, name } = e.target;
                          setAddProductPayLoad({
                            ...addProductPayLoad,
                            is_featured : isfeatured,
                          });
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                      Please select Yes or No
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Product Code"
                      required
                      name="product_code"
                      onChange={(e) => {
                        setAddProductPayLoad({
                          ...addProductPayLoad,
                          product_code: Number(e.target.value),
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Product code is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Brand Description</label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ height: "100px" }}
                      placeholder="Enter Product Description"
                      required
                      name="product_desc"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Brand name is required
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="insideForm">
                    <label className="inputLabel">Product image</label>
                    <br />
                    <label htmlFor="SelectFile" className="selectFileBox">
                      <input
                        type="file"
                        id="SelectFile"
                        className="d-none"
                        onChange={(e) => {
                          setAddProductPayLoad({
                            ...addProductPayLoad,
                            product_image: e.target.files[0],
                          });
                        }}
                      />
                      <img src={FileSelect} className="fileImg" alt="" />
                      <label htmlFor="" className="innerFIleTXt">
                        Drop your files here.
                        <br />
                        or <span className="diffColor">Browse</span>
                      </label>
                    </label>
                  </div>
                </div>
              </div>
              {addProductPayLoad.product_image && (
                <div className="previewSection">
                  <label className="previewLabel">Preview</label>
                  <div className="previewImg">
                    <img
                      // src={FileSelect}
                      src={
                        addProductPayLoad.product_image &&
                        URL.createObjectURL(addProductPayLoad.product_image)
                      }
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="text-end">
              <button
                type="button"
                className="rejectBtn me-2"
                onClick={() => navigate("/vetPetInventory")}
              >
                Cancel
              </button>
              <button className="AcceptBtn">Save</button>
            </div>
          </div>
        </Form>
      </motion.div>
    </>
  );
};

export default AddPetProduct;
