import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({
  data,
  pageCount,
  onPageChange,
  usersPerPage,
  currentPage,
  searchValue,
  searchedDataPageCounts,
}) => {

  return (
    <div
      className="row mt-4"
      style={{
        position: "absolute",
        bottom: "-45px",
        right: "25px",
      }}
    >
      <div className="col-lg-12">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={onPageChange}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          //   forcePage={currentPage}
        />
      </div>
    </div>
  );
};

export default Pagination;
